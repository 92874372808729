.register-form {
  width: 50%;
  margin: 0 auto;
  margin-top: 20px;
}
.register-form input {
  display: block;
  width: 100%;
  height: 50px;
  padding-left: 10px;
  font-size: 18px;
  margin-bottom: 15px;
}
